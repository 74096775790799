import React, { createContext, useReducer, useEffect } from 'react'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { API_PATH } from '../utils/constants'

//AWS Cognito
const poolData = {
  UserPoolId: 'eu-central-1_Rz5VgoYlJ',
  ClientId: '651m3hufd8kepkbscb5vvq13pi',
}
export const UserPool = new CognitoUserPool(poolData)

//App context
const initialState = {
  user: null,
  loanData: null,
}

export const StateContext = createContext()
export const DispatchContext = createContext()

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        user: null,
      }
    case 'SET_LOAN_DATA':
      return {
        ...state,
        loanData: action.payload,
      }
    default:
      throw new Error(`Unknown action type ${action.type}`)
  }
}

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contextReducer, initialState)

  const fetchLoanData = async () => {
    let data = await fetch(`${API_PATH}/caskloans-app/sheets-read-loans`)
    data = await data.json()

    dispatch({
      type: 'SET_LOAN_DATA',
      payload: {
        availableLoansCount: data.availableLoansCount,
        totalLoansCount: data.totalLoansCount,
      },
    })
    return data
  }

  useEffect(() => {
    fetchLoanData()
  }, [])

  // useEffect(() => {
  //   if(state.user)console.log("run user")
  // }, [state.user])

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export default ContextProvider
